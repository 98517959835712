//function affiche le menu 
$(document).ready(function() {
  var siteURL = (function() { return $('#get_host').html();})();
  $('#mobile_menu').css('display','block');
  $('#mobile_menu_background').css('display', 'initial');
  var IE11 = !!window.msCrypto;
  isSafari = !!navigator.userAgent.match(/safari/i) && !navigator.userAgent.match(/chrome/i) && typeof document.body.style.webkitFilter !== "undefined" && !window.chrome;
});

$(window).on('resize', function(){
  //win pour windows(fenetre)
  var win = $(this);
  menu_position = $('#mobile_menu').position();
  if (win.width() >= 768 && menu_position.left == 0 ) {
    $('#menu_burger').click();
  }
});

$(document).ready(function() {
  $('#menu_burger, #mobile_menu_background').on('click', function() {
    //remove de burger et affichage du menu 
    if (typeof menu_mobile_display === 'undefined' || menu_mobile_display == 0) {
      $('#mobile_menu').css({"transform":"translateX(100%)"});
      $('#mobile_menu_background').css({"transform":"translateX(100%)"});     
      $('#menu_burger').removeClass('d-block');
      $('#menu_burger').addClass('d-none');
      $('#menu_burger').toggleClass('fa-bars').toggleClass('fa-times');
      menu_mobile_display = 1;
    }
    else {
      //adaptation de translateX selon les tailles de l'écran 
      if (window.innerWidth > 575.98 && window.innerWidth < 767.98) {
        $('#mobile_menu').css({"transform":"translateX(-275px)"});
      } else if (window.innerWidth > 767.98 && window.innerWidth < 991.98) {
        $('#mobile_menu').css({"transform":"translateX(-370px)"});
      } else {
        $('#mobile_menu').css({"transform":"translateX(-250px)"});
      }
        $('#mobile_menu_background').css({"transform":"translateX(-100%)"});
        $('#menu_burger').removeClass('d-none');
        $('#menu_burger').addClass('d-block');
        $('#menu_burger').toggleClass('fa-bars').toggleClass('fa-times');
      menu_mobile_display = 0;
    }
  });

});

//naviguer entre les elements dans le corps
 $(document).ready(function (){
    $("body").on("click", "#nav-about-us, #nav-about-use", function (){
        $('html, body').animate({
            scrollTop: $("#about-us").offset().top-40,
        });
        //trop long mais 8000 permet d'avoir une defilement fluid
    });
});

 $(document).ready(function (){
    $("body").on("click", "#nav-contact-us, #nav-contact-use", function (){
        $('html, body').animate({
            scrollTop: $("#contact-us").offset().top-70,
        });
                //trop long mais 17000 permet d'avoir une defilement fluid

    });
});

