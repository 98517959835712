$(document).ready(function() {
    $("#submit").on("click", function() {
            telephone = $("#phone").val();
            telephone = telephone.split(' ').join('');
            telephone = telephone.split('.').join('');
            name = $("#Name").val();
            surname = $("#Surname").val();
            email = $("#Email").val();
            company = $("#Company").val();
            message = $("#Message").val();
            CheckboxBuyCar = $("#CheckboxBuyCar").is(':checked');            
            CheckboxSellCar = $("#CheckboxSellCar").is(':checked');

            if (requiredFields() == true) {
                //desactiver le boutton 
                $('#submit').prop('disabled', true);  
                $.ajax({
                    method: "POST",
                    url: "query/demande_rappel",
                    data: {
                        'telephone': telephone,
                        'name': name,
                        'surname': surname,
                        'email': email,
                        'company': company,
                        'message': message,
                        'CheckboxBuyCar': CheckboxBuyCar,
                        'CheckboxSellCar': CheckboxSellCar,
                    }
                }).done(function() {
                    // toastr.success('Your email has been sent', 'Succès');             
                    window.setTimeout(function() {
                        $(this).hide();
                        //supprimer les données d'un champs
                        // $("#phone").val("");
                        
                        //on cache tous les champs 
                        $("#contactchamps").hide();

                        //on affiche le message de succes
                        $("#success").show();

                        
                    });
                    return false;
                })
            } 
        });
    return false;
});

function requiredFields() {
    var errors = [];
    var superiors =[];

    //vérification si un champs est null 
    $("input[name^='input-']").each( function() {
            if (
                ($(this).prop('tagName').toLowerCase() == "input" && $(this).val() == "") 
                ||($(this).prop('tagName').toLowerCase() == "select" && $(this).children("option:selected").val() == "")
                ||($(this).hasClass('email') && regexEmail(email) == false )
                ||($(this).hasClass('phone') && regexPhoneNumber(telephone) == false )
                ||($(this).hasClass('checkboxes') && (CheckboxSellCar == false && CheckboxBuyCar == false))
                ||($(this).prop('tagName').toLowerCase() == "input" && $(this).val().length > $(this).attr("length"))
            ) {
                errors.push(this.id);
        }   else{ 
            $("input[name^='input-']").css('border','1px solid #ced4da');
        }
    });
    for (error in errors) {
        $('#'+errors[error]).addClass('form-required');
        $('#'+errors[error]).css('border','1px solid red');
       
    }
    if (errors.length >= 1 ) { 
        return false;
    } else {
    return true;
    };


}


//verification of email
function regexEmail(str) {
    const  regexEmail= /^\w+([\.-]?\w+)*@\w+([\.-0-9]?\w+)*(\.\w{2,3})+$/; 
  
    if (str.match(regexEmail)) {
        return true;
    } else {
        return false;
    }
}

//verification of phone number
function regexPhoneNumber(str) {
    //cette expression régulière accepte tous les numéros de l'Europe commencent par 
    // 00 ou + 
    // et 3chiffres (420) ou 2chiffres (33) ou 1chiffre(entre 1 et 9 (russie))  
    // et 1 chiffre du 6 du 06 par exemple 
    // et 4 couple de 2 numeros 11 22 33 44 
    const regexPhoneNumber = /^(?:(?:00|\+)(?:([0-9][0-9][0-9])|([0-9][0-9])|[1-9])|0)[1-9](\d{2}){4}$/; 
    if (str.match(regexPhoneNumber)) {
        return true;
    } else {
        return false;
    }
} 
